<template>
  <b-row class="divvy-form__container">
    <b-col class="divvy-form company">
      <form-wizard
        color="#5BD69E"
        :title="null"
        :subtitle="null"
        finish-button-text="Abrir conta"
        next-button-text="Avançar"
        back-button-text="Voltar"
        class="vertical-steps mb-3 divvy-form__form"
        @on-complete="submitForm">
        <div class="company__logo" />

        <tab-content title="" icon="feather" :before-change="validationForm">
          <h1 class="divvy-form__title">Faça o seu cadastro</h1>
          <validation-observer ref="registerForm">
            <b-form class="auth-divvy-form mt-2">

              <!-- cnpj -->
              <b-form-group label="CNPJ" label-for="register-cnpj">
                <validation-provider #default="{ errors }" name="CNPJ" rules="required">
                  <b-form-input
                    id="register-name"
                    v-model="dataForm.numberCpfCnpj"
                    v-mask="['##.###.###/####-##']"
                    :state="errors.length > 0 ? false : null"
                    placeholder="00.000.000/0000-00" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- corporate name -->
              <b-form-group label="Razão Social" label-for="register-razao">
                <validation-provider #default="{ errors }" name="Razão Social" rules="required">
                  <b-form-input
                    id="register-razaoSozial"
                    v-model="dataForm.razaoSozial"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Razão Social" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                  <b-form-input
                    id="register-email"
                    v-model="dataForm.emailAddress"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                     />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- address
              <b-form-group label="Endereço" label-for="register-address">
                <validation-provider #default="{ errors }" name="Endereço" rules="required">
                  <b-form-input
                    id="register-address"
                    v-model="dataForm.address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Rua XXXXX, Bairro XXXXX, Numero XXXXX" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- Cep -->
              <b-form-group label="CEP" label-for="register-cep">
                <validation-provider #default="{ errors }" name="Cep" rules="required">
                  <b-form-input
                    id="register-cep"
                    v-model="dataForm.cep"
                    v-mask="['#####-###']"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: 6040-000" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Rua -->
              <b-form-group label="Rua" label-for="register-rua">
                <validation-provider #default="{ errors }" name="Rua" rules="required">
                  <b-form-input
                    id="register-rua"
                    v-model="dataForm.rua"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: Av. Santos Dumont" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Endereco Numero -->
              <b-form-group label="Número" label-for="register-numero">
                <validation-provider #default="{ errors }" name="Numero" rules="required">
                  <b-form-input
                    id="register-numero"
                    v-model="dataForm.numero"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: 01" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Complemento -->
              <b-form-group label="Complemento" label-for="register-complemento">
                <validation-provider #default="{ errors }" name="Complemento" rules="required">
                  <b-form-input
                    id="register-complemento"
                    v-model="dataForm.complemento"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex,: Apto. 10 Bloco 4B" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Bairro -->
              <b-form-group label="Bairro" label-for="register-bairro">
                <validation-provider #default="{ errors }" name="Bairro" rules="required">
                  <b-form-input
                    id="register-bairro"
                    v-model="dataForm.bairro"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: Lago Sul" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Cidade -->
              <b-form-group label="Cidade" label-for="register-cidade">
                <validation-provider #default="{ errors }" name="Cidade" rules="required">
                  <b-form-input
                    id="register-cidade"
                    v-model="dataForm.cidade"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: Brasilia" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Estado -->

              <b-form-group label="Estado" label-for="register-uf">
                <validation-provider #default="{ errors }" name="Estado" rules="required">
                  <b-form-select
                  id="register-uf"
                  v-model="dataForm.uf"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value"
                  />
                  <!--<v-select
                    id="register-uf"
                    v-model="dataForm.uf"
                    label="title"
                    :options="listaUf"
                    :reduce="title => title.value" /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            <!-- phoneNumber -->
              <b-form-group label="Telefone" label-for="register-phoneNumber">
                <validation-provider #default="{ errors }" name="Telefone" rules="required">
                  <b-form-input
                    id="register-phoneNumber"
                    v-model="dataForm.phoneNumber"
                    v-mask="['(##) #####-####']"
                    :state="errors.length > 0 ? false : null"
                    placeholder="(21) 99999-9999" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-row v-if="isExternal === false">
                <b-col md="6">
                  <!-- Password -->
                  <b-form-group class="pass" label-for="Password" label="Senha">
                    <validation-provider #default="{ errors }" name="Senha" rules="required|password">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input
                          id="Password"
                          v-model.lazy="dataForm.password"
                          class="form-control-merge"
                          :type="passwordFieldTypeOne"
                          :state="errors.length > 0 ? false : null"
                          name="Password"
                          placeholder="············"
                          min="8" />
                        <b-input-group-append class="color-input-group company-color-input-group" is-text>
                          <feather-icon :icon="passwordToggleIconOne" class="cursor-pointer" @click="togglePasswordVisibilityOne" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <!-- Password -->
                  <b-form-group label-for="repeat-password" label="Confirmar Senha">
                    <validation-provider #default="{ errors }" name="Confirmar senha" rules="required|confirmed:Senha">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input
                          id="repeat-password"
                          v-model="dataForm.repeatPassword"
                          class="form-control-merge"
                          :type="passwordFieldTypeTwo"
                          :state="errors.length > 0 ? false : null"
                          name="repeat-password"
                          placeholder="Confirmr senha" />
                        <b-input-group-append class="broker__input-group-append company-color-input-group" is-text>
                          <feather-icon :icon="passwordToggleIconTwo" class="cursor-pointer" @click="togglePasswordVisibilityTwo" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>

              <div class="mt-2 button-center-position">
                <b-link class="back" @click="$router.go(-1)"> Voltar </b-link>
              </div>
            </b-form>
          </validation-observer>
        </tab-content>

        <tab-content title="" icon="feather" :before-change="validationFile">
          <validation-observer ref="registerFile">
            <h1 class="divvy-form__title">Análise de Dados</h1>

            <p class="divvy-form__analysis-description">
              Iremos analisar os documentos para validar acesso e liberação para
              anunciar na nossa plataforma da melhor forma possível.
            </p>

            <div class="divvy-form__file-container">
              <b-form-group label="Cartão CNPJ" label-for="img_cnpj">
                <validation-provider #default="{ errors }" name="CNPJ" rules="required">
                  <b-form-file
                      id="img_cnpj"
                      ref="img_cnpj"
                      v-model="dataForm.attachments"
                      name="img_cnpj"
                      :state="errors.length > 0 ? false : null"
                      :accept="typeFiles"
                      placeholder=""
                      required
                      @change="getBase64($event, 'img_cnpj')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <validation-provider #default="{ errors }" name="Telefone" rules="required">
              <b-form-checkbox
                id="therms-of-use"
                v-model="dataForm.termsOfUse"
                class="divvy-form__analysis-description mt-5"
                name="checkbox-1"
                value="true"
                :state="errors.length > 0 ? false : null"
                unchecked-value="false">
                Concordo com os
                <a
                  class="term"
                  target="_blank"
                  rel="noreferer noopener"
                  href="/terms"
                  >Termos de Uso</a
                >
                e
                <a
                  class="term"
                  target="_blank"
                  rel="noreferer noopener"
                  href="/privacy"
                  >Política de Privacidade</a
                >
                <small class="text-danger display_block">{{ errors[0] }}</small>
              </b-form-checkbox>
            </validation-provider>
          </validation-observer>
          <!-- <div class="progress-wrapper">
            <b-card-text class="mb-0">
              Reticulating splines… {{ progress+'%' }}
            </b-card-text>
            <b-progress
              v-model="progress"
              max="100"
            />
          </div> -->
        </tab-content>
      </form-wizard>
      <b-overlay :show="show" no-wrap />
    </b-col>
    <b-col class="register-background">
      <div class="register-background-text">
        <h1 class="register-background-text__title">
          Anúncie o seu imóvel de forma fácil e acessível
          <span class="register-background-text__title--company">!</span>
        </h1>
        <p class="register-background-text__paragraph">
          Simples, fácil e seguro para qualquer pessoa investir no mercado
          imobiliário.
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
  BLink,
  BFormCheckbox,
  BFormSelect
  // BProgress,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { togglePasswordVisibility, togglePasswordVisibilityOne, togglePasswordVisibilityTwo } from '@core/mixins/ui/forms'
import { required } from '@validations'
import vSelect from 'vue-select'
import toast from '@/mixins/toast'
import ValidationCpfCnpjService from '@/services/ValidationCpfCnpjService'
import StateService from '@/services/StateService'
import dataTypeFiles from '../../helper/dataTypeFiles'

export default {
  components: {
    
    BOverlay,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BLink,
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormSelect,
    //vSelect,
    // BProgress,
  },
  mixins: [togglePasswordVisibility, togglePasswordVisibilityOne, togglePasswordVisibilityTwo, toast],

  data: () => ({
    listaUf: [],
    optionsResponsible: {},
    show: false,
    isExternal: false,
    typeFiles: dataTypeFiles.types,
    dataForm: {
      razaoSozial: '',
      numberCpfCnpj: '',
      address: '',
      emailAddress: '',
      cep: '',
      rua: '',
      numero: 0,
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      phoneNumber: '',
      password: '',
      repeatPassword: '',
      attachments: [],
      termsOfUse: '',
    },
    required,
    errors: '',
    passwordFieldTypeOne: 'password',
    passwordFieldTypeTwo: 'password',
    warning: 'Arquivo a ser anexado deve ter até 1MB',

    progress: '',
    max: '',
  }),

  computed: {
    passwordToggleIconOne() {
      return this.passwordFieldTypeOne === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconTwo() {
      return this.passwordFieldTypeTwo === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  async mounted() {
    const item = localStorage.getItem('externalLogin')
    const responseEstates = await StateService.getAll()

    this.listaUf = responseEstates.data.result.items.map(res => ({
      /*value: res.estate.id,
      code: res.estate.code,
      title: res.estate.name,
      initials: res.estate.initials, */
      value: res.estate.id,
      text: res.estate.name,
    }))

    if (Boolean(item) === true) {
      localStorage.removeItem('externalLogin')
      await this.getUserDataExternal()
    }
  },

  methods: {
    validateNumber: event => {
      const { keyCode } = event
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },
    submitForm() {
      this.registerData()
    },

    async getUserDataExternal() {
      this.isExternal = true
      const model = {
        authProvider: localStorage.getItem('authProvider'),
        providerKey: localStorage.getItem('providerKey'),
        providerAccessCode: localStorage.getItem('providerAccessCode'),
        singleSignIn: false,
      }
      const response = await this.$http.post(
        '/api/TokenAuth/GetExternalUserInfo',
        model,
        { validateStatus: () => true },
      )
      // eslint-disable-next-line prefer-const
      let { result } = response.data
      // eslint-disable-next-line prefer-template
      this.dataForm.razaoSozial = result.name + ' ' + result.surname
      this.dataForm.emailAddress = result.emailAddress
    },

    async validationForm() {
      if (this.dataForm.numberCpfCnpj !== '') {
        const { result } = (await ValidationCpfCnpjService.validaCnpj(this.dataForm.numberCpfCnpj)).data
        if (result === false) {
          this.error = 'Número de CNPJ inválido'
          this.msgError()
          return
        }
      }
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.registerForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFile() {
      return new Promise((resolve, reject) => {
        this.$refs.registerFile.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    registerData() {
      const dataCompany = this.treatDataCompanyRegister()
      this.register(dataCompany)
    },

    treatDataCompanyRegister() {
      const data = {
        address: this.dataForm.address,
        street: this.dataForm.rua,
        zipcode: this.dataForm.cep,
        number: this.dataForm.numero,
        aditionalInfo: this.dataForm.complemento,
        neighborhood: this.dataForm.bairro,
        city: this.dataForm.cidade,
        uf: this.dataForm.uf,
        situation: 1,
        user: {
          Name: this.dataForm.razaoSozial,
          Surname: this.dataForm.razaoSozial,
          UserName: this.dataForm.emailAddress,
          razaoSozial: this.dataForm.razaoSozial,
          numberCpfCnpj: this.dataForm.numberCpfCnpj.replace(/[^\d]+/g, ''),
          emailAddress: this.dataForm.emailAddress,
          phoneNumber: this.dataForm.phoneNumber,
          password: this.dataForm.password,
          typePerson: 'J',
          termsOfUse: this.dataForm.termsOfUse,
        },
        authProvider: localStorage.getItem('authProvider'),
        providerKey: localStorage.getItem('providerKey'),
        providerAccessCode: localStorage.getItem('providerAccessCode'),
        binaryCnpj: {
          bytes: this.dataForm.attachments.img_cnpj ? this.dataForm.attachments.img_cnpj.split(',')[1] : null,
          description: this.dataForm.attachments.name ? this.dataForm.attachments.name : null,
          dataType: this.dataForm.attachments.img_cnpj ? this.dataForm.attachments.img_cnpj.split(',')[0] : null,
        },
      }

      return data
    },

    async register(data) {
      try {
        this.show = true
        const config = {

          validateStatus: () => true,
          onUploadProgress: event => {
            const p = Math.round(
              (event.loaded * 100) / event.total,
            )
            this.progress = p
            if (p === 100) {
              this.max = 100
            }
          },
        }
        let endpoint = '/api/services/app/ConstructorPublicService/CreateConstructor'
        if (this.isExternal) {
          endpoint = '/api/services/app/ConstructorPublicService/CreateConstructorExternal'
          // eslint-disable-next-line no-param-reassign
          data.user.password = '12345678910'
        }
        const response = await this.$http.post(endpoint, data, config)
        if (response.data.error) {
          this.show = false
          this.error = response.data.error.message
          this.msgError(this.error)
          return
        }

        this.show = false
        this.msgSuccess()
        setTimeout(() => {
          this.$router.push({ name: 'company-registered' })
        }, 2700)
      } catch (error) {
        this.show = false
        this.error = error.message
        this.msgError()
      }
    },

    async getBase64(event, key) {
      if (this.checkSizeFile(event) !== 1) {
        this.clearFiles(event)
        this.msgWarning()
        return
      }
      const reader = new FileReader()
      // eslint-disable-next-line no-return-assign
      reader.onload = e => (this.dataForm.attachments[key] = e.target.result)
      reader.readAsDataURL(event.target.files[0])
    },

    checkSizeFile(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        return
      }

      // eslint-disable-next-line consistent-return
      return 1
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'info',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    clearFiles(event) {
      this.$refs[event.target.id].reset()
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.error}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    replaceCpfOrCnpj(v) {
    // eslint-disable-next-line no-param-reassign
      v = v.replace(/\D/g, '')
      if (v.length <= 14) {
      // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      } else {
      // eslint-disable-next-line no-param-reassign
        v = v.replace(/^(\d{2})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{4})(\d)/, '$1-$2')
      }
      return v
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
@import '../sass/variables.scss';
@import '../sass/divvy-form.scss';
@import '../sass/company.scss';
@import '../sass/vertical-form-wizard.scss';
@import '../sass/register-background-text.scss';

a.back {
  color: #000;
  font-weight: 500;
  cursor: pointer;
}
a.term {
  color: #5bd69e !important;
}
.display_block {
  display: block;
}
.button-center-position {
  position: absolute;
  margin-top: 85px !important;
  left: 47.3%;
}
.register-background {
  display: none;

  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-image: url('~@/assets/images/pages/background_company.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
}

.company {
  &__logo {
    width: 43px;
    height: 44px;
    background-image: url('~@/assets/images/logo/real-state-logo.png');
  }

  & input {
    &:focus {
      border-color: #5bd69e !important;
    }
  }
  a {
    color: #000;
    font-weight: 500;
  }
}
.input-group {
  &:not(.bootstrap-touchspin):focus-within {
    .company-color-input-group > div.input-group-text {
      border-color: #5bd69e !important;
    }
  }
}

.custom-select:focus {
  border-color: var(--color) !important;
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

.custom-select:disabled {
  color: #3e3e40;
}

.input-group {
  &:not(.bootstrap-touchspin):focus-within {
    .color-input-group > div.input-group-text {
      border-color: #5bd69e!important;
    }
  }
}
  .vue-form-wizard
  .wizard-card-footer
  .wizard-footer-right
  .wizard-btn:hover {
  box-shadow: 0 8px 25px -8px #5bd69e;
}

.vertical-steps.vue-form-wizard .wizard-navigation .wizard-nav {
  position: absolute;
  top: 0px;
  right: 0px;
}

.company .is-invalid {
  &:not(.bootstrap-touchspin):focus-within {
    .input-group-append > div.input-group-text {
      border-color: #ea5455 !important;
    }
  }
}
</style>
