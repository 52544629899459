var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"divvy-form__container"},[_c('b-col',{staticClass:"divvy-form company"},[_c('form-wizard',{staticClass:"vertical-steps mb-3 divvy-form__form",attrs:{"color":"#5BD69E","title":null,"subtitle":null,"finish-button-text":"Abrir conta","next-button-text":"Avançar","back-button-text":"Voltar"},on:{"on-complete":_vm.submitForm}},[_c('div',{staticClass:"company__logo"}),_c('tab-content',{attrs:{"title":"","icon":"feather","before-change":_vm.validationForm}},[_c('h1',{staticClass:"divvy-form__title"},[_vm._v("Faça o seu cadastro")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-divvy-form mt-2"},[_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"register-cnpj"}},[_c('validation-provider',{attrs:{"name":"CNPJ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['##.###.###/####-##']),expression:"['##.###.###/####-##']"}],attrs:{"id":"register-name","state":errors.length > 0 ? false : null,"placeholder":"00.000.000/0000-00"},model:{value:(_vm.dataForm.numberCpfCnpj),callback:function ($$v) {_vm.$set(_vm.dataForm, "numberCpfCnpj", $$v)},expression:"dataForm.numberCpfCnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Razão Social","label-for":"register-razao"}},[_c('validation-provider',{attrs:{"name":"Razão Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-razaoSozial","state":errors.length > 0 ? false : null,"placeholder":"Razão Social"},model:{value:(_vm.dataForm.razaoSozial),callback:function ($$v) {_vm.$set(_vm.dataForm, "razaoSozial", $$v)},expression:"dataForm.razaoSozial"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.dataForm.emailAddress),callback:function ($$v) {_vm.$set(_vm.dataForm, "emailAddress", $$v)},expression:"dataForm.emailAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CEP","label-for":"register-cep"}},[_c('validation-provider',{attrs:{"name":"Cep","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"}],attrs:{"id":"register-cep","state":errors.length > 0 ? false : null,"placeholder":"Ex.: 6040-000"},model:{value:(_vm.dataForm.cep),callback:function ($$v) {_vm.$set(_vm.dataForm, "cep", $$v)},expression:"dataForm.cep"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Rua","label-for":"register-rua"}},[_c('validation-provider',{attrs:{"name":"Rua","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-rua","state":errors.length > 0 ? false : null,"placeholder":"Ex.: Av. Santos Dumont"},model:{value:(_vm.dataForm.rua),callback:function ($$v) {_vm.$set(_vm.dataForm, "rua", $$v)},expression:"dataForm.rua"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Número","label-for":"register-numero"}},[_c('validation-provider',{attrs:{"name":"Numero","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-numero","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Ex.: 01"},model:{value:(_vm.dataForm.numero),callback:function ($$v) {_vm.$set(_vm.dataForm, "numero", $$v)},expression:"dataForm.numero"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Complemento","label-for":"register-complemento"}},[_c('validation-provider',{attrs:{"name":"Complemento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-complemento","state":errors.length > 0 ? false : null,"placeholder":"Ex,: Apto. 10 Bloco 4B"},model:{value:(_vm.dataForm.complemento),callback:function ($$v) {_vm.$set(_vm.dataForm, "complemento", $$v)},expression:"dataForm.complemento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Bairro","label-for":"register-bairro"}},[_c('validation-provider',{attrs:{"name":"Bairro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-bairro","state":errors.length > 0 ? false : null,"placeholder":"Ex.: Lago Sul"},model:{value:(_vm.dataForm.bairro),callback:function ($$v) {_vm.$set(_vm.dataForm, "bairro", $$v)},expression:"dataForm.bairro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Cidade","label-for":"register-cidade"}},[_c('validation-provider',{attrs:{"name":"Cidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cidade","state":errors.length > 0 ? false : null,"placeholder":"Ex.: Brasilia"},model:{value:(_vm.dataForm.cidade),callback:function ($$v) {_vm.$set(_vm.dataForm, "cidade", $$v)},expression:"dataForm.cidade"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Estado","label-for":"register-uf"}},[_c('validation-provider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"register-uf","label":"title","options":_vm.listaUf,"reduce":function (title) { return title.value; }},model:{value:(_vm.dataForm.uf),callback:function ($$v) {_vm.$set(_vm.dataForm, "uf", $$v)},expression:"dataForm.uf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Telefone","label-for":"register-phoneNumber"}},[_c('validation-provider',{attrs:{"name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####']),expression:"['(##) #####-####']"}],attrs:{"id":"register-phoneNumber","state":errors.length > 0 ? false : null,"placeholder":"(21) 99999-9999"},model:{value:(_vm.dataForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dataForm, "phoneNumber", $$v)},expression:"dataForm.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.isExternal === false)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"pass",attrs:{"label-for":"Password","label":"Senha"}},[_c('validation-provider',{attrs:{"name":"Senha","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"Password","type":_vm.passwordFieldTypeOne,"state":errors.length > 0 ? false : null,"name":"Password","placeholder":"············","min":"8"},model:{value:(_vm.dataForm.password),callback:function ($$v) {_vm.$set(_vm.dataForm, "password", $$v)},expression:"dataForm.password"}}),_c('b-input-group-append',{staticClass:"color-input-group company-color-input-group",attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOne},on:{"click":_vm.togglePasswordVisibilityOne}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2994334990)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"repeat-password","label":"Confirmar Senha"}},[_c('validation-provider',{attrs:{"name":"Confirmar senha","rules":"required|confirmed:Senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"repeat-password","type":_vm.passwordFieldTypeTwo,"state":errors.length > 0 ? false : null,"name":"repeat-password","placeholder":"Confirmr senha"},model:{value:(_vm.dataForm.repeatPassword),callback:function ($$v) {_vm.$set(_vm.dataForm, "repeatPassword", $$v)},expression:"dataForm.repeatPassword"}}),_c('b-input-group-append',{staticClass:"broker__input-group-append company-color-input-group",attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconTwo},on:{"click":_vm.togglePasswordVisibilityTwo}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,58017268)})],1)],1)],1):_vm._e(),_c('div',{staticClass:"mt-2 button-center-position"},[_c('b-link',{staticClass:"back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Voltar ")])],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"","icon":"feather","before-change":_vm.validationFile}},[_c('validation-observer',{ref:"registerFile"},[_c('h1',{staticClass:"divvy-form__title"},[_vm._v("Análise de Dados")]),_c('p',{staticClass:"divvy-form__analysis-description"},[_vm._v(" Iremos analisar os documentos para validar acesso e liberação para anunciar na nossa plataforma da melhor forma possível. ")]),_c('div',{staticClass:"divvy-form__file-container"},[_c('b-form-group',{attrs:{"label":"Cartão CNPJ","label-for":"img_cnpj"}},[_c('validation-provider',{attrs:{"name":"CNPJ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"img_cnpj",attrs:{"id":"img_cnpj","name":"img_cnpj","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":"","required":""},on:{"change":function($event){return _vm.getBase64($event, 'img_cnpj')}},model:{value:(_vm.dataForm.attachments),callback:function ($$v) {_vm.$set(_vm.dataForm, "attachments", $$v)},expression:"dataForm.attachments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('validation-provider',{attrs:{"name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"divvy-form__analysis-description mt-5",attrs:{"id":"therms-of-use","name":"checkbox-1","value":"true","state":errors.length > 0 ? false : null,"unchecked-value":"false"},model:{value:(_vm.dataForm.termsOfUse),callback:function ($$v) {_vm.$set(_vm.dataForm, "termsOfUse", $$v)},expression:"dataForm.termsOfUse"}},[_vm._v(" Concordo com os "),_c('a',{staticClass:"term",attrs:{"target":"_blank","rel":"noreferer noopener","href":"/terms"}},[_vm._v("Termos de Uso")]),_vm._v(" e "),_c('a',{staticClass:"term",attrs:{"target":"_blank","rel":"noreferer noopener","href":"/privacy"}},[_vm._v("Política de Privacidade")]),_c('small',{staticClass:"text-danger display_block"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":""}})],1),_c('b-col',{staticClass:"register-background"},[_c('div',{staticClass:"register-background-text"},[_c('h1',{staticClass:"register-background-text__title"},[_vm._v(" Anúncie o seu imóvel de forma fácil e acessível "),_c('span',{staticClass:"register-background-text__title--company"},[_vm._v("!")])]),_c('p',{staticClass:"register-background-text__paragraph"},[_vm._v(" Simples, fácil e seguro para qualquer pessoa investir no mercado imobiliário. ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }